import React from "react";
import "./scrollTemplateMobile.scss";

import PlaceHolderImage from "../../assets/images/image_placeholder.png"; // Importa a imagem padrão

interface ScrollTemplateMobileProps {
  icon: string;
  image: string;
  title: string;
  description: string;
  color: string;
}

function ScrollTemplateMobile({
  title,
  description,
  icon,
  image,
  color,
}: ScrollTemplateMobileProps) {
  return (
    <div className="scrollable-item-mobile">
      <div className="mobile-img-container">
        <img
          src={PlaceHolderImage}
          alt="Placeholder"
          className="placeholder-image"
          style={{ background: color }}
        />{" "}
        {/* Imagem padrão */}
      </div>
      <div className="mobile-content">
        <img src={icon} alt={`${title} Icon`} className="icon" /> {/* Ícone */}
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default ScrollTemplateMobile;
