import React, { useEffect } from "react";
import "./scrollTemplate.scss";

interface ScrollTemplateProps {
  icon: string;
  image: string;
  title: string;
  description: string;
  color: string;
  isFirst?: boolean
  isLast?: boolean
  posicaoScroll: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>	
  index: number
}

function ScrollTemplate({ title, description, icon, image, posicaoScroll, isFirst, setCurrentIndex, index}: ScrollTemplateProps) {

  const containerRef = React.useRef<HTMLDivElement>(null);
  
  const [isHidden, setIsHidden] = React.useState(false);

  useEffect(() => {
    const containerTop = containerRef.current?.getBoundingClientRect().top!;

    if (containerTop < 300 && containerTop > -600) {
      setCurrentIndex(index);
    }

    setIsHidden(containerTop < 600)
  }, [posicaoScroll])

  return (
    <div className={`scrollable-item-container`} ref={containerRef} >

      <div className={`left-panel ${isHidden ? 'visible' : ''}`}>
        <div className="content">
          <img src={icon} />

          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>

      <div className="right-panel" >
        <div className="grid-bg" />
        <div className="bottom-img-container">
          <img className="bottom-img" src={image} />
        </div>
      </div>
    </div>
  );
}

export default ScrollTemplate;
