import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "./featureSection.scss";

import featureImage from "../../assets/images/featureImage.svg";
import seachCircleIcon from "../../assets/icons/searchCircleIcon.svg";
import cardRectangle from "../../assets/images/cardRectangle.svg";
import cardRectanglePink from "../../assets/images/cardRectanglePink.svg";
import lineLeft1 from "../../assets/images/lineLeft1.svg";
import lineLeft2 from "../../assets/images/lineLeft2.svg";
import lineRight1 from "../../assets/images/lineRight1.svg";
import lineRight2 from "../../assets/images/lineRight2.svg";
import lockCircleIcon from "../../assets/icons/lockCircleIcon.svg";
import shieldCircleIcon from "../../assets/icons/shieldCircleIcon.svg";
import cardCircle from "../../assets/images/cardCircle.svg";
import questionIcon from "../../assets/icons/questionIcon.svg";
import lineMiddle from "../../assets/images/lineMiddle.svg";
import sheetCircleIcon from "../../assets/icons/sheetCircleIcon.svg";
import bugCircleIcon from "../../assets/icons/bugCircleIcon.svg";
import { useTranslation } from "react-i18next";


// Component para desktop
function FeatureSectionDesktop() {
  const { t, } = useTranslation("FeatureSection");

  
  return (
    <div className="featureCards">
      {/* Card 1 - Modelagem de Ameaça */}
      <div className="featureCard">
        <img className="line lineLeft1" src={lineLeft1} alt="line" />
        <div className="featureCardHeader">
          <img className="circleIcon" src={seachCircleIcon} alt="Icon" />
          <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
          <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
          <h3>{t("modelagem")}</h3>
        </div>
        <div className="featureCardBody">
          <img className="cardCircle" src={cardCircle} alt="circle" />
          <div className="featureBlock">
            <div className="featureCardTitle">{t("metrifica")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("metrificaDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("identificacaoAmeacas")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("identificacaoAmeacasDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("definicaoMitigacoes")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("definicaoMitigacoesDesc")}</div>
          </div>
        </div>
      </div>

      {/* Card 2 - Conector SAST/DAST */}
      <div className="featureCard">
        <img className="line lineLeft2" src={lineLeft2} alt="line" />
        <div className="featureCardHeader">
          <img className="circleIcon" src={lockCircleIcon} alt="Icon" />
          <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
          <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
          <h3>{t("conector")}</h3>
        </div>
        <div className="featureCardBody">
          <img className="cardCircle" src={cardCircle} alt="circle" />
          <div className="featureBlock">
            <div className="featureCardTitle">{t("integracaoPipeline")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("integracaoPipelineDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("automacaoTestes")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("automacaoTestesDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("relatoriosCentralizados")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("relatoriosCentralizadosDesc")}</div>
          </div>
        </div>
      </div>

      {/* Card 3 - Gestão de Pentest */}
      <div className="featureCard">
        <img className="line lineMiddle" src={lineMiddle} alt="line" />
        <div className="featureCardHeader">
          <img className="circleIcon" src={sheetCircleIcon} alt="Icon" />
          <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
          <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
          <h3>{t("gestaoPentest")}</h3>
        </div>
        <div className="featureCardBody">
          <img className="cardCircle" src={cardCircle} alt="circle" />
          <div className="featureBlock">
            <div className="featureCardTitle">{t("escopoPentest")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("escopoPentestDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("execucaoRelatorios")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("execucaoRelatoriosDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("correcaoReavaliacao")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("correcaoReavaliacaoDesc")}</div>
          </div>
        </div>
      </div>

      {/* Card 4 - Gestão de Bug Bounty */}
      <div className="featureCard">
        <img className="line lineRight2" src={lineRight2} alt="line" />
        <div className="featureCardHeader">
          <img className="circleIcon" src={bugCircleIcon} alt="Icon" />
          <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
          <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
          <h3>{t("gestaoBugBounty")}</h3>
        </div>
        <div className="featureCardBody">
          <img className="cardCircle" src={cardCircle} alt="circle" />
          <div className="featureBlock">
            <div className="featureCardTitle">{t("regrasRecompensas")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("regrasRecompensasDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("validacaoBugs")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("validacaoBugsDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("aprimoramentoContinuo")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("aprimoramentoContinuoDesc")}</div>
          </div>
        </div>
      </div>

      {/* Card 5 - Security Champions */}
      <div className="featureCard">
        <img className="line lineRight1" src={lineRight1} alt="line" />
        <div className="featureCardHeader">
          <img className="circleIcon" src={shieldCircleIcon} alt="Icon" />
          <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
          <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
          <h3>{t("securityChampions")}</h3>
        </div>
        <div className="featureCardBody">
          <img className="cardCircle" src={cardCircle} alt="circle" />
          <div className="featureBlock">
            <div className="featureCardTitle">{t("facilitadorEquipes")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("facilitadorEquipesDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("treinamentoContinuo")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("treinamentoContinuoDesc")}</div>
          </div>
          <div className="featureBlock">
            <div className="featureCardTitle">{t("culturaSeguranca")}<img className="questionIcon" src={questionIcon} alt="circle" /></div>
            <div className="featureCardDescription">{t("culturaSegurancaDesc")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Component para mobile com Swiper
function FeatureSectionMobile() {
  const { t, } = useTranslation("FeatureSection");

  return (
    <Swiper
      modules={[Pagination]}
      pagination={{ clickable: true }}
      spaceBetween={16}
      slidesPerView={1}
      style={{ overflow: "visible" }}
    >
      {/* Slide 1 */}
      <SwiperSlide>
        <div className="featureCard">
          <img className="line lineLeft1" src={lineLeft1} alt="line" />
          <div className="featureCardHeader">
            <img className="circleIcon" src={seachCircleIcon} alt="Icon" />
            <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
            <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
            <h3>{t("modelagem")}</h3>
          </div>
          <div className="featureCardBody">
            <img className="cardCircle" src={cardCircle} alt="circle" />
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("metrifica")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("metrificaDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("identificacaoAmeacas")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("identificacaoAmeacasDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("definicaoMitigacoes")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("definicaoMitigacoesDesc")}</div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Slide 2 */}
      <SwiperSlide>
        <div className="featureCard">
          <img className="line lineLeft2" src={lineLeft2} alt="line" />
          <div className="featureCardHeader">
            <img className="circleIcon" src={lockCircleIcon} alt="Icon" />
            <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
            <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
            <h3>{t("conector")}</h3>
          </div>
          <div className="featureCardBody">
            <img className="cardCircle" src={cardCircle} alt="circle" />
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("integracaoPipeline")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("integracaoPipelineDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("automacaoTestes")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("automacaoTestesDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("relatoriosCentralizados")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("relatoriosCentralizadosDesc")}</div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Slide 3 */}
      <SwiperSlide>
        <div className="featureCard">
          <img className="line lineMiddle" src={lineMiddle} alt="line" />
          <div className="featureCardHeader">
            <img className="circleIcon" src={sheetCircleIcon} alt="Icon" />
            <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
            <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
            <h3>{t("gestaoPentest")}</h3>
          </div>
          <div className="featureCardBody">
            <img className="cardCircle" src={cardCircle} alt="circle" />
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("escopoPentest")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("escopoPentestDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("execucaoRelatorios")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("execucaoRelatoriosDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("correcaoReavaliacao")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("correcaoReavaliacaoDesc")}</div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Slide 4 */}
      <SwiperSlide>
        <div className="featureCard">
          <img className="line lineRight2" src={lineRight2} alt="line" />
          <div className="featureCardHeader">
            <img className="circleIcon" src={bugCircleIcon} alt="Icon" />
            <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
            <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
            <h3>{t("gestaoBugBounty")}</h3>
          </div>
          <div className="featureCardBody">
            <img className="cardCircle" src={cardCircle} alt="circle" />
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("regrasRecompensas")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("regrasRecompensasDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("validacaoBugs")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("validacaoBugsDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("aprimoramentoContinuo")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("aprimoramentoContinuoDesc")}</div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Slide 5 */}
      <SwiperSlide>
        <div className="featureCard">
          <img className="line lineRight1" src={lineRight1} alt="line" />
          <div className="featureCardHeader">
            <img className="circleIcon" src={shieldCircleIcon} alt="Icon" />
            <img className="cardRectangle" src={cardRectangle} alt="Rectangle" />
            <img className="cardRectanglePink" src={cardRectanglePink} alt="Rectangle" />
            <h3>{t("securityChampions")}</h3>
          </div>
          <div className="featureCardBody">
            <img className="cardCircle" src={cardCircle} alt="circle" />
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("facilitadorEquipes")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("facilitadorEquipesDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("treinamentoContinuo")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("treinamentoContinuoDesc")}</div>
            </div>
            <div className="featureBlock">
              <div className="featureCardTitle">
                {t("culturaSeguranca")}<img className="questionIcon" src={questionIcon} alt="circle" />
              </div>
              <div className="featureCardDescription">{t("culturaSegurancaDesc")}</div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

// Componente principal para exibir a versão correta com base no tamanho da tela
function FeatureSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="featureSection">
      <div className="imgContainer">
        <img src={featureImage} alt="Feature Icon" />
        <span>
          Centralize todo o processo de
          <br /> desenvolvimento seguro em um único lugar.
        </span>
        <div className="featureCardsTextShadow">AI POWERED</div>
      </div>

      <div className="featureCardsContainer">
        {isMobile ? (
          <div className="featureCards">
            <FeatureSectionMobile />
          </div>
        ) : (
          <div className="">
            <FeatureSectionDesktop />
          </div>
        )}
      </div>
    </section>
  );
}

export default FeatureSection;
