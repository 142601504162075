import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./heroSection.scss";
import closeIcon from "../../../assets/icons/close.svg";
import guyGlass from "../../../assets/icons/guy-glass.svg";
import riskSquadLogo from "../../../assets/icons/riskSquadLogo.svg";

interface heroSectionProps {
  children: React.ReactNode;
}

function HeroSection({ children }: heroSectionProps) {
  const { t } = useTranslation("HeroSection");
  const { t: t2 } = useTranslation("Header");
  const [isOpen, setIsOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const toggleFormOpen = () => {
    setIsOpen(!isOpen);

    setTimeout(() => {
      setIsFormOpen(!isFormOpen);
    }, 160);
  };

  const toggleFormSuccess = () => {
    if (isFormSuccess) {
      toggleFormOpen();
    }
    setIsFormSuccess(!isFormSuccess);
  };

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as any, { toggleFormOpen });
    }
    return child;
  });


  return (
    <section className="heroSection">
      {childrenWithProps}

      <div className="heroContent">
        <button className="trialButton">
          {t("hero.trialButton")}{" "}
          <span className="nowBadge">{t("hero.nowBadge")}</span>
        </button>

        <div className="heroContainer">
          <div className="heroLeft">
            <h1>
              {t("hero.title")
                .split("\n")
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </h1>
          </div>

          <div className="heroRight">
            <p>
              {t("hero.description")
                .split("\n")
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
            <div className="ctaButtons">
              <button className="ctaButton" onClick={toggleFormOpen}>
                {t("hero.ctaTrial")}
              </button>
              <button className="ctaButton">{t("hero.ctaDemo")}</button>
            </div>
          </div>
        </div>
      </div>

      {isFormOpen && (
        <>
          <div className="backdrop"></div>
          <div className='form-dialog'>

            <div className={`dialog-container ${isOpen ? "" : "close"}`}>
              <div className='logo-container'>
                <button className="close-container" onClick={toggleFormOpen}>
                  <img className="close" src={closeIcon} alt="burger Icon" />

                </button>
                <img className="logo" src={riskSquadLogo} alt="RiskSquad Logo" style={{ width: '242px' }} />
              </div>

              <div className='profile'>
                <img src={guyGlass} alt="guy glass" />
                <div className="text-info">
                  <h3 className="form-title">{t2("Header.formTitle1")}</h3>
                  <h3 className="form-title2" style={{ marginTop: '0px' }}>{t2("Header.formTitle2")}</h3>
                  <p>{t2("Header.formSubtitle")}</p>
                </div>
              </div>

              <div className="form">
                <div className="form-container">
                  <label htmlFor="name-input">{t2("Header.formFields.name")}</label>
                  <input type="text" id="name-input" placeholder={t2("Header.placeholders.name")} />
                </div>

                <div className="form-container">
                  <label htmlFor="email-input">{t2("Header.formFields.email")}</label>
                  <input type="email" id="email-input" placeholder={t2("Header.placeholders.email")} />
                </div>

                <div className="form-container">
                  <label htmlFor="phone-input">{t2("Header.formFields.phone")}</label>
                  <input type="tel" id="phone-input" placeholder={t2("Header.placeholders.phone")} />
                </div>

                <div className="form-container">
                  <label htmlFor="website-input">{t2("Header.formFields.website")} <span>({t2("Header.optional")})</span></label>
                  <input type="text" id="website-input" placeholder={t2("Header.placeholders.website")} />
                </div>

                <div className="form-container">
                  <label htmlFor="linkedin-input">{t2("Header.formFields.linkedin")} <span>({t2("Header.optional")})</span></label>
                  <input type="text" id="linkedin-input" placeholder={t2("Header.placeholders.linkedin")} />
                </div>

                <button className="ctaButton" onClick={toggleFormSuccess}>{t2("Header.send")}</button>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default HeroSection;
