import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./infoTabs.scss";

import cubeSmall from "../../assets/images/cube-small.svg";
import cubeLarge from "../../assets/images/cube-large.svg";
import cubeMedium from "../../assets/images/cube-medium.svg";
import { useTranslation } from "react-i18next";

// Definindo tabs como um array constante e inferindo seus tipos
const tabs = ["Ciso", "Time Dev", "Team Leader"] as const;

// Criando um tipo que representa os valores possíveis de `tabs`
type TabType = (typeof tabs)[number]; // "Ciso" | "Time Dev" | "Team Leader"

// Definindo posições específicas para cada aba
const tabPositions = {
  desktop: {
    Ciso: 315,
    "Time Dev": 90,
    "Team Leader": -222,
  },
  mobile: {
    Ciso: 150,
    "Time Dev": 50,
    "Team Leader": -100,
  },
};

// Função para retornar a cor do texto e ícone com base na aba ativa
const getStyles = (selectedTab: TabType) => {
  switch (selectedTab) {
    case "Ciso":
      return { color: "#F80091", iconColor: "#F80091", borderCollor: "#F80091" };
    case "Time Dev":
      return { color: "#343CF9", iconColor: "#343CF9", borderCollor: "#343CF9" };
    case "Team Leader":
      return {
        color: "transparent",
        borderCollor: "#9848FF",
        backgroundImage: "linear-gradient(8deg, #9848FF 1%, #F80091 100%)",
        WebkitBackgroundClip: "text",
        iconColor: "#9848FF",
      };
    default:
      return { color: "#ffffff0", iconColor: "#ffffff0" }; // Valores padrões para cor e ícone
  }
};

// Componente do Ícone
const Icon = ({ color }: { color: string }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M30.5327 6L15.7565 25.7015C15.59 25.9236 15.3778 26.1074 15.1341 26.2403C14.8904 26.3733 14.6209 26.4523 14.3441 26.472C14.0672 26.4917 13.7893 26.4517 13.5292 26.3546C13.2691 26.2575 13.0329 26.1057 12.8367 25.9094L8.99219 22.065"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8.25528 26.0309C8.07616 26.2141 7.86249 26.3599 7.62663 26.4599C7.39078 26.5599 7.13742 26.6122 6.88124 26.6136C6.62506 26.6151 6.37113 26.5657 6.13417 26.4683C5.8972 26.3709 5.6819 26.2275 5.50073 26.0464L1.65625 22.2084"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M23.0159 6.13696L12.5039 20.1538"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

const InfoTabs = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useTranslation("InfoTabs");

  const [selectedTab, setSelectedTab] = useState<TabType>("Time Dev");

  const { borderCollor, color, iconColor, ...textStyle } = getStyles(selectedTab);

  // Definir a posição com base no estado `isMobile`
  const tabPosition = isMobile
    ? tabPositions.mobile[selectedTab]
    : tabPositions.desktop[selectedTab];

  const cubePositions = {
    Ciso: [
      { x: "-33vw", y: "-20vh", src: cubeSmall },  // Cubo médio
      { x: "35vw", y: "-42vh", src: cubeLarge },    // Cubo grande
      { x: "-36vw", y: "-60vh", src: cubeMedium },  // Cubo pequeno
    ],
    "Time Dev": [
      { x: "-32vw", y: "-25vh", src: cubeSmall },  // Cubo médio
      { x: "33vw", y: "-45vh", src: cubeLarge },    // Cubo grande
      { x: "-47vw", y: "-50vh", src: cubeMedium },  // Cubo pequeno
    ],
    "Team Leader": [
      { x: "-26vw", y: "-13vh", src: cubeSmall },    // Cubo médio
      { x: "35vw", y: "-32vh", src: cubeLarge },    // Cubo grande
      { x: "-36vw", y: "-40vh", src: cubeMedium },  // Cubo pequeno
    ],
  };

  return (
    <section className="infocontainer">
      <h1>Risksquad for</h1>
      <div className="tabs-wrapper">
        {/* Container das Abas com Animação */}
        <motion.div
          className="tabs"
          animate={{ x: tabPosition }}
          transition={{ type: "spring", stiffness: 80, damping: 12 }}
        >

          {/* Títulos das Abas */}
          {tabs.map((tab) => (
            <div
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`tab ${selectedTab === tab ? "active" : ""}`}
              style={selectedTab === tab ? getStyles(selectedTab) : {}}

            >
              <span className="tab-title">{tab}</span>
            </div>
          ))}
        </motion.div>
        <div
          className="borda-central"
          style={{ backgroundColor: borderCollor }}
        />
        {/* Conteúdo das Abas */}
        <div className="tab-content">
          <AnimatePresence mode="wait">
            {["Ciso", "Time Dev", "Team Leader"].includes(selectedTab) && (
              <motion.div
  key={selectedTab}
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={{ duration: 0.2, ease: "easeInOut" }}
  className="content"
>
  <div className="card-container">
    {selectedTab === "Ciso" && (
      <>
        <div className="card">
          <Icon color={iconColor} />
          {t("Ciso.0")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("Ciso.1")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("Ciso.2")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("Ciso.3")}
        </div>
      </>
    )}
    {selectedTab === "Time Dev" && (
      <>
        <div className="card">
          <Icon color={iconColor} />
          {t("TimeDev.0")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("TimeDev.1")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("TimeDev.2")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("TimeDev.3")}
        </div>
      </>
    )}
    {selectedTab === "Team Leader" && (
      <>
        <div className="card">
          <Icon color={iconColor} />
          {t("TeamLeader.0")}
        </div>
        <div className="card">
          <Icon color={iconColor} />
          {t("TeamLeader.1")}
        </div>
        <div className="card large">
          <Icon color={iconColor} />
          {t("TeamLeader.2")}
        </div>
      </>
    )}
  </div>
</motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      {cubePositions[selectedTab].map((pos, index) => (
        <motion.img
          key={index}
          className="cube"
          src={pos.src}
          alt={`Cube ${index + 1}`}
          initial={{ opacity: 0 }}
          animate={{ x: pos.x, y: pos.y, opacity: 1 }}
          transition={{ type: "spring", stiffness: 80, damping: 12, duration: 0.8 }}
        />
      ))}
    </section>
  );
};

export default InfoTabs;
