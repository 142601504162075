import React, { useRef, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import "./advantagesSection.scss";
import advantageGridLines from "../../../assets/images/advantageGridLines.png";

const AdvantagesSection = forwardRef(function AdvantagesSection(
  { isMobile }: { isMobile: boolean },
  ref: any
) {
  const { t } = useTranslation("AdvantagesSection");
  const scrollRef = useRef<HTMLDivElement>(null);

  const smoothScroll = (scrollAmount: number) => {
    const container = scrollRef.current;
    if (!container || isMobile) return;

    let start = container.scrollLeft;
    let change = scrollAmount;
    let duration = 200;
    let currentTime = 0;
    const increment = 40;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      container.scrollLeft = val;
      if (currentTime < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };
    animateScroll();
  };

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const handleWheel = (event: WheelEvent) => {
    const container = scrollRef.current;

    if (container && !isMobile) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const currentScrollLeft = container.scrollLeft;

      if (
        (currentScrollLeft > 0 && event.deltaY < 0) ||
        (currentScrollLeft < maxScrollLeft && event.deltaY > 0)
      ) {
        event.preventDefault();
        smoothScroll(event.deltaY);
      }
    }
  };

  useEffect(() => {
    const container = scrollRef.current;
    if (container && !isMobile) {
      container.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, [isMobile]);

  return (
    <section className="advantagesSection" ref={ref}>
      <div className="advantagesGridLines">
        <div className="advantagesDiv">
          <div className="advantagesText">
            <h1 className="desktop">{t("titleDesktop")}</h1>
            <h1 className="mobile">{t("titleMobile")}</h1>
          </div>
        </div>

        <div className="advantagesScroll" ref={scrollRef}>
          <div className="advantage-item">
            <h2>2x</h2>
            <p>{t("advantageSpeed")}</p>
          </div>
          <div className="advantage-item">
            <h2>ROI</h2>
            <p>{t("advantageROI")}</p>
          </div>
          <div className="advantage-item">
            <h2 className="percentH2">
              3
              <span className="percentHolder">
                0<span className="percent">%</span>
              </span>
            </h2>
            <p>{t("advantageSavings")}</p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default AdvantagesSection;
