import React from "react";
import { useTranslation } from "react-i18next";
import "./aboutSection.scss";

import coworking from "../../../assets/images/coworking.png";
import coworkingGradient from "../../../assets/images/coworkingGradient.png";
import riskSquadLogoGradient from "../../../assets/icons/riskSquadLogoGradient.png";
import doubleArrowDown from "../../../assets/icons/doubleArrowDown.svg";

function AboutSection({ isMobile }: { isMobile: boolean }) {
  const { t } = useTranslation("AboutSection");

  return (
    <section>
      <div className="descriptionContainer">
        {isMobile ? (
          <>
            <span className="description">{t("descriptionMobile")}</span>
            <span className="gradient-text">
              <span className="description">{t("descriptionHighlight1")}</span>
              <span className="description blackText"> a </span>
              <span className="description">{t("descriptionHighlight2")}</span>
            </span>
          </>
        ) : (
          <>
            <span className="description">{t("descriptionDesktop")}</span>
            <span className="gradient-text">
              <span className="description">{t("descriptionHighlight1")}</span>
              <span className="description blackText"> a </span>
              <span className="description">{t("descriptionHighlight2")}</span>
            </span>
          </>
        )}

        <p className="descriptionInfo">{t("descriptionInfo")}</p>
      </div>

      <div className="container">
        <div className="img-grid-container">
          <img
            className="co-working-image"
            src={coworking}
            alt="co-working-image"
          />
          <img
            className="co-working-gradient-image"
            src={coworkingGradient}
            alt="co-working-gradient-image"
          />
          <img
            className="risk-squad-logo-gradient"
            src={riskSquadLogoGradient}
            alt="Risksquad Logo"
          />
        </div>

        <div className="white-card-container">
          <div className="white-card">
            <span>{t("whiteCardText")}</span>
            <img
              className="double-arrow-down-icon"
              src={doubleArrowDown}
              alt="double-arrow-down-icon"
            />
            <button className="ctaButton">{t("ctaButton")}</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
