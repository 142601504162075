import React from 'react';
import './footer.scss';

import bigTextFooter from "../../assets/images/bigTextFooter.svg"
import riskSquadLogo from "../../assets/icons/riskSquadLogo.svg"

function Footer() {
  return (
    <footer className="footer" style={{overflow: 'hidden'}}>
      <div className="footerTop">
        <div className="footerLogo">
          <img src={riskSquadLogo} alt="RiskSquad Logo" style={{width: '242px'}}/>
        </div>
        <button className="footerButton">Falar com consultor</button>
      </div>

      <hr className="footerSeparator" />

      <div className="footerBottom">
        <span className="footerText">All rights reserved© 2024 Risksquad.</span>
        <div className="footerLinks">
          <a href="https://www.youtube.com" target="_blank" rel="noreferrer">Youtube</a>
          <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">Linkedin</a>
        </div>
        
      </div>
      <img className="bigTextFooter" src={bigTextFooter} alt="RiskSquad Logo" />
    </footer>
  );
}

export default Footer;
